import { graphql } from 'gatsby';
import React from 'react';
import { ProductTemplate } from '@matix/gatsby-theme-shopify';
const PageQuery = (props) => {
   return <ProductTemplate {...props} />;
};

export const pageQuery = graphql`
   query productPageTemplateShopifyThemeCore($locale: String, $uuid: String) {
      pageQuery: matixShopifyProduct(frontmatter: { uuid: { eq: $uuid } }, locale: { eq: $locale }) {
         locale
         creationDate
         modificationDate
         ...ProductDetailsThemeShopify
      }
      pageQueryLocale: allMatixMdx(filter: { frontmatter: { uuid: { eq: $uuid } } }) {
         nodes {
            ...PageQueryLocaleThemeCore
         }
      }
   }
`;

export default PageQuery;
